import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import ExternalImage from "../ExternalImage";
import loadImage from "../../../Utils/imageLoader";
import "./ImageViewbox.scss";

const ImageViewbox = ({ open, handleClose, selectedImageData }) => {
  const theme = useTheme();

  const getImageSrc = (path) => {
    return loadImage(path);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      sx={{
        overflowY: "scroll",
      }}
    >
      <Fade in={open}>
        <Box
          className="modal-box"
          style={{ "--background-color": theme.palette.background.paper }}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {selectedImageData && (
            <>
              <Button onClick={handleClose} sx={{ fontSize: "1em", position: "fixed" }} variant="contained">
                <FontAwesomeIcon icon={faX} />
              </Button>
              <ExternalImage
                src={getImageSrc(selectedImageData.Resolutions.PreviewL.Path)}
                alt={selectedImageData.Alt || selectedImageData.FileName}
                className="modal-image"
                style={{
                  width: "100%",
                  maxHeight: "600px",
                  maxWidth: "1100px",
                  borderRadius: '1em',
                }}
                skelHeight={400}
              />
              <Box
                className="image-details"
                sx={{
                  textAlign: "center",
                  backgroundColor: theme.palette.background.default,
                  mt: 2,
                  borderRadius: 2,
                  p: 4,
                }}
              >
                <Typography variant="body">
                  Camera Model: {selectedImageData.CameraSettings.CameraModel} {<br></br>}
                </Typography>
                <Typography variant="body">
                  Lens Model: {selectedImageData.CameraSettings.LensModel} {<br></br>}
                </Typography>
                <Typography variant="body">
                  Shutter Speed: {selectedImageData.CameraSettings.ShutterSpeed} {<br></br>}
                </Typography>
                <Typography variant="body">
                  Aperture: {selectedImageData.CameraSettings.Aperture} {<br></br>}
                </Typography>
                <Typography variant="body">
                  ISO Value: {selectedImageData.CameraSettings.IsoValue} {<br></br>}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default ImageViewbox;
