import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Music.scss";
import { albums } from "../../Utils/albumInfo";
import { musicImages } from "../../Utils/musicImages";
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';

const Music = () => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 710,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <Box className="music-container">
      <Box component="header" sx={{ mb: 4 }}>
        <Typography variant="h3">
          Music
        </Typography>
      </Box>
      <Divider sx={{mb: 1}} />
      <Box className="discog-container" style={{
        '--secondary-color': theme.palette.secondary.main,
      }}
>
        <Typography variant="h3" sx={{fontSize: '2.2em'}}>Discography</Typography>
        <Slider {...settings}>
          {albums.map((album, index) => (
            <Box key={index}>
            <Paper className="album-card" elevation={3}>
              <img src={album.imageUrl} alt="" className="album-art" />
              <Box className="album-card-header">
                <Typography variant="h5" color="secondary" className="album-card-title">
                  {album.title}
                </Typography>
                <Typography variant="subtitle" className="album-card-subtitle">
                  {album.subtitle}
                </Typography>
              </Box>
              <Box className="album-card-content">
                {album.content.map((line, idx) => (
                  <Typography key={idx} variant="body2">
                    {line}
                  </Typography>
                ))}
              </Box>
              <Divider />
              <Box className="album-card-actions">
                {album.links.map((link, linkIdx) => (
                  <Tooltip title={link.tooltip} key={linkIdx}>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      href={link.url}
                      aria-label={link.tooltip}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={link.icon} />
                    </IconButton>
                  </Tooltip>
                ))}
              </Box>
            </Paper>
          </Box>
          ))}
        </Slider>
      </Box>
      <Divider />
      <Box className="bottom-container">
        <Typography variant="h3" sx={{fontSize: '2.2em'}}>Videos</Typography>
        <Box className="bottom-left">
          <Box sx={{ width: "100%" }} className="video-container">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="fullWidth"
                scrollButtons
                allowScrollButtonsMobile
                className="tabs-container"
              >
                <Tab wrapped label="Find Myself Backyard Session" />
                <Tab wrapped label="The Ocean Bedroom Session" />
                <Tab wrapped label="The North Lyric Video" />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
            <iframe src="https://www.youtube.com/embed/43ZRqWlsE3g?si=cbdyOWBkeV-H-yDE" title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
            <iframe src="https://www.youtube.com/embed/80tqcY1n9pE?si=DqAXTPh3UryJhXiU" title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
            <iframe src="https://www.youtube.com/embed/aJ1nhXqiqqg?si=OqLYlx36NBLAHOLJ" title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen></iframe>
            </CustomTabPanel>
          </Box>
        </Box>
        <Box className="bottom-right">
          <Typography variant="h3" gutterBottom sx={{ mb: 3, textAlign: "center"}}>
            Media
          </Typography>
          <Grid container spacing={2}>
          {musicImages.map((image, index) => (
          <Grid item xs={16} md={4} key={index}>
            <Box className="image-container"
              sx={{
                margin: "auto",
                width: "100px",
                height: '400px',
                backgroundImage: `url(${image})`,
                backgroundSize: 'cover',
                borderRadius: '1em',
              }}
            ></Box>
          </Grid>
        ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default Music;
