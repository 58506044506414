import React, { useState, useCallback } from 'react';
import Skeleton from '@mui/material/Skeleton';

const ExternalImage = ({ src, alt, skelHeight, ...props }) => {
  const [loaded, setLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setLoaded(true);
  }, []);

  return (
    <>
      <img
        onLoad={onLoad}
        src={src}
        alt={alt}
        style={{ display: loaded ? 'block' : 'none' }}
        {...props}
      />
      {!loaded && (
        <Skeleton variant="rectangular" width="100%" height={skelHeight} animation="wave"/>
      )}
    </>
  );
};

export default ExternalImage;
