export const projects = [
    {
      id: 1,
      imageSrc: "../assets/Images/GalleryCreatorCover.png",
      title: "Gallery Creator",
      subtitle: "C#",
      description: "GalleryCreator, a comprehensive .NET solution specifically designed for the management and processing of dynamic photo galleries. This application is particularly fine-tuned for integration with Angular-based projects, offering a rich array of features for image handling. This is an ideal tool for photographers, web developers, and digital artists seeking an efficient and automated way to build a showcase for their photographic work online.",
      date: "Completed: January 2024",
      link: "https://github.com/DaveC6662/ESP32-Temperature-Server",
      linkLabel: "View Repository",
      iconClass: "fa-brands fa-github"
    },
    {
      id: 2,
      imageSrc: "../assets/Images/ESP-Server-Cover.png",
      title: "ESP32 Temperature Server",
      subtitle: "C / C++ / JavaScript",
      description: "The ESP32 Temperature Monitoring Server is a solution designed to capture and display real-time temperature readings using the DS18B20 digital temperature sensor. This project uses the ESP32's Wi-Fi capabilities to create a responsive web server that displays these temperature readings in a user-friendly format as well as sending webhook notifications.",
      date: "Completed: January 2024",
      link: "https://github.com/DaveC6662/GalleryCreator",
      linkLabel: "View Repository",
      iconClass: "fa-brands fa-github"
    },
    {
      id: 3,
      imageSrc: "../assets/Images/Highway-Racers_Cover.png",
      title: "Highway Racers",
      subtitle: "JavaScript",
      description: "In 'Highway Racers,' you take control of a high-performance car, speeding down a two lane highway filled with various obstacles and challenges. The objective is simple: drive as far as possible while avoiding collisions and earning points for your distance traveled.",
      date: "Completed: June 2024",
      link: "/game",
      linkLabel: "Play now!",
      iconClass: "fa fa-play",
      isButton: true
    }
  ];