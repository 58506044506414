import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TagChips from "../../Components/PhotographyComponents/TagChips";
import ImageGallery from "../../Components/PhotographyComponents/ImageGallery/ImageGallery";
import data from "../../assets/img/data.json";
import ImageViewbox from "../../Components/PhotographyComponents/ImageViewbox/ImageViewbox";
import "./Photography.scss";

const PhotographyGallery = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImageData, setSelectedImage] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <Box className="photography-container">
      <Box component="header" sx={{ mb: 4 }}>
        <Typography variant="h3">
          Photography
        </Typography>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <TagChips onSelectTags={setSelectedTags} selectedTags={selectedTags} />
      <ImageGallery selectedTags={selectedTags} data={data} onImageClick={handleOpen} />
      <ImageViewbox open={open} handleClose={handleClose} selectedImageData={selectedImageData} />
    </Box>
  );
};

export default PhotographyGallery;
