import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import loadImage from "../../../Utils/imageLoader";
import ExternalImage from "../ExternalImage";
import "./ImageGallery.scss"

const ImageGallery = ({ selectedTags, data, onImageClick }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const imagesPerPage = 12;

  const indexOfLastImage = currentPage * imagesPerPage;
  const indexOfFirstImage = indexOfLastImage - imagesPerPage;
  const currentImages = filteredData.slice(indexOfFirstImage, indexOfLastImage);

  const totalPages = Math.ceil(filteredData.length / imagesPerPage);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;

      const newFilteredData = data
        .filter(
          (item) =>
            selectedTags.length === 0 ||
            selectedTags.every((tag) => item.Tags.includes(tag))
        )
        .map((item) => {
          let path;
          if (width < 1024) {
            path = loadImage(item.Resolutions.PreviewS.Path);
          } else {
            path = loadImage(item.Resolutions.PreviewM.Path);
          } 
          return { ...item, displayPath: path };
        });

      setFilteredData(newFilteredData);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [selectedTags, data]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTags]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
      <Grid container spacing={2}>
        {currentImages.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ExternalImage
                className="image"
                src={item.displayPath}
                alt={item.alt || ""}
                style={{ width: "100%", height: "auto", borderRadius: "1em" }}
                onClick={() => onImageClick(item)}
                skelHeight={400}
              />
          </Grid>
        ))}
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 2 }}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </>
  );
};

export default ImageGallery;
