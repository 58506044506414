import React from 'react';

const GamePage = () => {
  return (
    <div style={{ width: '100vw', height: '100vh', overflow: 'hidden', position: 'relative' }}>
      <iframe
        title="Game"
        src={`${process.env.PUBLIC_URL}/game/index.html`}
        style={{ border: 'none', width: '100%', height: '100%', touchAction: 'none' }}
        allow="fullscreen"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GamePage;
