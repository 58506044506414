import theNorthImg from '../assets/Images/AlbumArt/TheNorth.png';
import habitsImg from '../assets/Images/AlbumArt/Habits.png';
import bonesImg from '../assets/Images/AlbumArt/Bones.png';
import realImg from '../assets/Images/AlbumArt/Real.png';
import newLoveImg from '../assets/Images/AlbumArt/NewLove.png';
import imwImg from '../assets/Images/AlbumArt/IMMW.png';
import { faApple, faSpotify, faBandcamp, faYoutube } from '@fortawesome/free-brands-svg-icons';

export const albums = [
    {
      imageUrl: theNorthImg,
      "title": "The North",
      "subtitle": "October 2020",
      "content": [
        "Written by: Davin Chiupka",
        "Recorded/Mixed by: Sam Guaiana",
        "Mastered by: Mike Kalajian"
      ],
      "links": [
        { "url": "https://music.apple.com/ca/album/the-north/1526185033?i=1526185034", icon: faApple, tooltip: "Apple Music" },
        { "url": "https://open.spotify.com/track/6ogtsGkw8JLkaxQPTowOb8?si=b8e71cc111fc49cc", icon: faSpotify, tooltip: "Spotify" },
        { "url": "https://davinchiupka.bandcamp.com/track/the-north", icon: faBandcamp, tooltip: "Bandcamp" },
        { "url": "https://music.youtube.com/watch?v=PilLDCQ82nA&si=kX9pk4Ksuh5Hqge7", icon: faYoutube, "tooltip": "Youtube Music" }
      ]
    },
    {
      imageUrl: habitsImg,
      "title": "Habits",
      "subtitle": "May 2020",
      "content": [
        "Written by: Davin Chiupka",
        "Recorded/Mixed by: Sam Guaiana",
        "Mastered by: Mike Kalajian",
        "Artwork by: Emma Rodriguez"
      ],
      "links": [
        { "url": "https://music.apple.com/ca/album/habits/1510131237?i=1510131238", icon: faApple, tooltip: "Apple Music" },
        { "url": "https://open.spotify.com/track/5DDbCtlrPEONWnlWO5UX0s?si=08a277ccf48b4f5b", icon: faSpotify, tooltip: "Spotify" },
        { "url": "https://davinchiupka.bandcamp.com/track/habits", icon: faBandcamp, tooltip: "Bandcamp" },
        { "url": "https://music.youtube.com/watch?v=NX3I9iIW-eQ&si=WGLyqLTtdi8l2fLg", icon: faYoutube, "tooltip": "Youtube Music" }
      ]
    },
    {
      imageUrl: bonesImg,
      "title": "Bones",
      "subtitle": "May 2018",
      "content": [
        "Written/Recorded by: Davin Chiupka"
      ],
      "links": [
        { "url": "https://music.apple.com/ca/album/bones/1383317077?i=1383317078", icon: faApple, tooltip: "Apple Music" },
        { "url": "https://open.spotify.com/track/35BGYu9aKqtLoub246UmYa?si=be8821850ddf4301", icon: faSpotify, tooltip: "Spotify" },
        { "url": "https://davinchiupka.bandcamp.com/track/bones", icon: faBandcamp, tooltip: "Bandcamp" },
        { "url": "https://music.youtube.com/watch?v=hyB_gVvxGNI&si=16eHwiJRhdzEoXUx", icon: faYoutube, "tooltip": "Youtube Music" }
      ]
    },
    {
      imageUrl: realImg,
      "title": "Real",
      "subtitle": "November 2017",
      "content": [
        "Written by: Davin Chiupka",
        "Recorded/Mixed by: Nick Cassidy"
      ],
      "links": [
        { "url": "https://music.apple.com/ca/album/real/1319609914?i=1319609917", icon: faApple, tooltip: "Apple Music" },
        { "url": "https://open.spotify.com/track/5m1FxHYoLvqtZHjk58ytr8?si=4591c4f66b0640bf", icon: faSpotify, tooltip: "Spotify" },
        { "url": "https://davinchiupka.bandcamp.com/track/real-2", icon: faBandcamp, tooltip: "Bandcamp" },
        { "url": "https://music.youtube.com/watch?v=5WzlUiF21ls&si=5nKptPq1DHcMymh5", icon: faYoutube, "tooltip": "Youtube Music" }
      ]
    },
    {
      imageUrl: newLoveImg,
      "title": "New Love",
      "subtitle": "March 2017",
      "content": [
        "Written by: Davin Chiupka",
        "Recorded/Mixed by: Nick Cassidy"
      ],
      "links": [
        { "url": "https://music.apple.com/ca/album/new-love/1211503343?i=1211503350", icon: faApple, tooltip: "Apple Music" },
        { "url": "https://open.spotify.com/track/0trGDu8HViyLjfluBovpv5?si=e19cd36b8c89438e", icon: faSpotify, tooltip: "Spotify" },
        { "url": "https://davinchiupka.bandcamp.com/track/new-love", icon: faBandcamp, tooltip: "Bandcamp" },
        { "url": "https://music.youtube.com/watch?v=o26MWWbFleQ&si=30gMKHdFNg4GPXZ1", icon: faYoutube, "tooltip": "Youtube Music" }
      ]
    },
    {
      imageUrl: imwImg,
      "title": "It Makes Me Worry",
      "subtitle": "September 2016",
      "content": [
        "Written/Recorded by: Davin Chiupka"
      ],
      "links": [
        { "url": "https://music.apple.com/ca/album/it-makes-me-worry-ep/1149704083", icon: faApple, tooltip: "Apple Music" },
        { "url": "https://open.spotify.com/album/7dPdda5zNPgSxLHeLjT4dm?si=dDqozqyXTX6n7JYHhw2afQ", icon: faSpotify, tooltip: "Spotify" },
        { "url": "https://davinchiupka.bandcamp.com/album/it-makes-me-worry", icon: faBandcamp, tooltip: "Bandcamp" },
        { "url": "https://music.youtube.com/playlist?list=OLAK5uy_nDQ-Usvw5gZ3NM50tKVR2zK9DMrPF1u2c&si=XFUSCnqtempLf7CH", icon: faYoutube, "tooltip": "Youtube Music" }
      ]
    }
  ];