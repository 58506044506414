import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import Zoom from '@mui/material/Zoom';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import ThemedIcon from '../CustomIcons/ThemedIcon';
import {faArrowUp} from "@fortawesome/free-solid-svg-icons";

const PREFIX = 'ScrollToTop';
const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const ScrollToTop = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <Root className={classes.root} sx={{zIndex: 10000}}>
      <Tooltip title="Scroll to top">
      <Zoom in={visible}>
        <Fab  sx={{backgroundColor: "white"}} size="medium" onClick={scrollToTop} aria-label="scroll back to top">
          <ThemedIcon icon={faArrowUp} color="secondary" size={'2x'}/>
        </Fab>
      </Zoom>
      </Tooltip>
    </Root>
  );
};

export default ScrollToTop;
