import img1 from "../../src/assets/img/Lg/AbandonedCabin.jpg"
import img2 from "../../src/assets/img/Lg/LakeSuperiorRocks.jpg"
import img3 from "../../src/assets/img/Lg/HangingTreeMoss.jpg"
import img4 from "../../src/assets/img/Lg/BabyBlueBridge.jpg"
import img5 from "../../src/assets/img/Lg/Foggytrees.jpg"
import img6 from "../../src/assets/img/Lg/IceBlocks.jpg"
import img7 from "../../src/assets/img/Lg/OddFellowsHall.jpg"
import img8 from "../../src/assets/img/Lg/SunsetSilhouet.jpg"

export const heroImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
  ];