import img1 from "../../src/assets/Images/Music/Davin-Chiupka-All-White-1.png"
import img2 from "../../src/assets/Images/Music/Davin-Chiupka-All-White-2.png"
import img3 from "../../src/assets/Images/Music/Davin-Chiupka-All-White-3.png"
import img4 from "../../src/assets/Images/Music/Davin-Chiupka-All-White-4.png"
import img5 from "../../src/assets/Images/Music/ICWCComeBackShow.png"
import img6 from "../../src/assets/Images/Music/OttawaTour.png"
import img7 from "../../src/assets/Images/Music/TheBout.png"
import img8 from "../../src/assets/Images/Music/ThePaintedLadyCMW.png"

export const musicImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
  ];