import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ThemedIcon from "../CustomIcons/ThemedIcon";
import { socialLinks } from "../../Utils/socialLinks";
import "./Footer.scss";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";

const Footer = ({ isLightTheme, toggleTheme }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    isDesktop && (
      <AppBar position="fixed" sx={{ top: 'auto', bottom: '0px' }} color="default" className="footer">
        <Toolbar>
          <Box className="footer-content">
            <div className="footer-social">
              {socialLinks.map((link, index) => (
                <Tooltip key={index} title={link.title}>
                  <IconButton href={link.href} target="_blank" color="primary">
                    <ThemedIcon icon={link.icon} />
                  </IconButton>
                </Tooltip>
              ))}
            </div>
            <Box className="theme-toggle" sx={{ margin: "auto", position: "absolute", left: "48%", translateX: "-50%" }}>
              <Tooltip title="Toggle Light/Dark Mode">
                <span>
                  <Switch checked={isLightTheme} onChange={toggleTheme} />
                  <ThemedIcon icon={isLightTheme ? faSun : faMoon} />
                </span>
              </Tooltip>
            </Box>
            <Box className="footer-copy">
              <Typography color="secondary">&copy; 2023 Davin Chiupka.</Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    )
  );
};

export default Footer;
