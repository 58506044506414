import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import loadImage from '../../Utils/generalImageLoader';
import { projects } from '../../Utils/codeProjects';
import './Code.scss';

const Code = () => {
  const { pathname } = useLocation();
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setFadeIn(true);
  }, [pathname]);

  return (
    <Container className={`code-container ${fadeIn ? 'fade-in' : ''}`} maxWidth="lg">
      <Box component="header" sx={{ mb: 4 }}>
        <Typography variant="h3">
          Code Portfolio
        </Typography>
      </Box>
      <Divider />
      <Box component="main" sx={{ mt: 4 }}>
        <Box component="section" id="projects">
          <Typography variant="h4" component="h2" gutterBottom>
            Projects
          </Typography>
          <Grid container spacing={2}>
            {projects.map((project) => (
              <Grid item key={project.id} xs={12} sm={8} lg={4} sx={{ m: 'auto' }}>
                <Card className="project-card">
                  <CardMedia
                    component="img"
                    image={loadImage(project.imageSrc)}
                    alt={`${project.title} Project Screenshot`}
                    className="project-card-image"
                  />
                  <CardContent className="project-card-content">
                    <Typography variant="h5">
                      {project.title}
                    </Typography>
                    <Typography variant="h6"color="text.secondary">
                      {project.subtitle}
                    </Typography>
                    <Typography variant="body">
                      {project.description}
                    </Typography>
                  </CardContent>
                  <CardActions className="project-card-actions">
                    <Typography variant="h6" component="span" color="text.secondary" sx={{ mb: 4 }}>
                      {project.date}
                    </Typography>
                    {project.isButton ? (
                      <Button
                        color="primary"
                        onClick={() => window.location.href = project.link}
                        title={project.linkLabel}
                        startIcon={<i className={project.iconClass}></i>}
                      >
                        {project.linkLabel}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        component="a"
                        href={project.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={project.linkLabel}
                        startIcon={<i className={project.iconClass}></i>}
                      >
                        {project.linkLabel}
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Code;
