import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';

const ColoredIcon = styled(FontAwesomeIcon)(({ theme, color }) => ({
    color: color === "primary" ? theme.palette.primary.main : theme.palette.secondary.main
}));

const ThemedIcon = ({icon, size, color}) => {
  const theme = useTheme();

  return (
    <ColoredIcon icon={icon} size={size} theme={theme} color={color} />
  );
};

export default ThemedIcon;


