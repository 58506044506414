import musicImage from "../assets/Images/vinyl-image.jpg";
import photoImage from "../assets/Images/camera-image.jpg";
import codeImage from "../assets/Images/code-image.jpg";

export const sections = [
  {
    title: "Music",
    link: "/music",
    backgroundImage: musicImage,
    description: "Dive into my creative music projects, spanning genres from rock to alternative folk, showcasing my journey over the years.",
  },
  {
    title: "Photography",
    link: "/photography",
    backgroundImage: photoImage,
    description: "Explore my photography portfolio, showcasing everything from intricate macro shots to dynamic concert captures.",
  },
  {
    title: "Code",
    link: "/code",
    backgroundImage: codeImage,
    description: "Discover a range of projects that highlight my innovative and exploratory approach to learning programming.",
  },
];
