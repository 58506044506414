import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import { heroImages } from "../../Utils/heroImages";
import { Link } from "react-router-dom";
import { sections } from "../../Utils/homeSections";
import { useTheme, useMediaQuery } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { StyledCard, StyledCardMedia, Content, useStyles } from "../../Components/Custom Cards/StyledCard";
import "./Home.scss";

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * heroImages.length);
  return heroImages[randomIndex];
};

const cardVariants = {
  offscreen: {
    y: 300,
    rotate: -10,
  },
  onscreen: {
    y: 0,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.2,
      duration: 2.5,
    },
  },
};

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const heroImage = getRandomImage();
  const classes = useStyles();

  const threshold = isMobile ? 0.01 : 0.2;

  const aboutMeRef = useRef(null);
  const myWorkRefs = useRef([]);

  const aboutMeControls = useAnimation();
  const cardControls = useAnimation();

  useEffect(() => {
    const aboutMeElement = aboutMeRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          aboutMeControls.start("visible");
        }
      },
      { threshold }
    );

    if (aboutMeElement) {
      observer.observe(aboutMeElement);
    }

    return () => {
      if (aboutMeElement) {
        observer.unobserve(aboutMeElement);
      }
    };
  }, [aboutMeRef, aboutMeControls, threshold]);

  useEffect(() => {
    const myWorkElement = myWorkRefs.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          if (entry.isIntersecting) {
            cardControls.start("onscreen");
          }
        });
      },
      { threshold }
    );

    myWorkElement.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      myWorkElement.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [myWorkRefs, cardControls, threshold]);

  return (
    <Box
      className="home-container"
      style={{ "--contrast-color": theme.palette.secondary.contrastText }}
    >
      <motion.div
        className="hero-section"
        style={{ backgroundImage: `url(${heroImage})` }}
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1, transition: { duration: 2 } },
        }}
      >
        <motion.div
          className="hero-content"
          style={{
            "--background-color": theme.palette.background.default,
            "--primary-color": theme.palette.primary,
          }}
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { y: "-100%" },
            visible: { y: 0, transition: { duration: 1 } },
          }}
        >
          <Typography
            variant="h2"
            className="hero-title"
            sx={{ fontWeight: 600 }}
          >
            Welcome to My Creative World
          </Typography>
          <Typography
            variant="h5"
            className="hero-subtitle"
            sx={{ fontWeight: 400 }}
          >
            Your one-stop destination for my music, photography, and coding
            projects.
          </Typography>
        </motion.div>
        <Box className="overlay"></Box>
      </motion.div>
      <Box className="bottom-container">
        <motion.div
          className="about-me-section"
          ref={aboutMeRef}
          initial="hidden"
          animate={aboutMeControls}
          variants={{
            hidden: { y: "100%" },
            visible: { y: 0, transition: { duration: 1 } },
          }}
        >
          <Paper
            elevation={8}
            className="about-me"
            sx={{ mt: 2, backgroundColor: theme.palette.secondary.light }}
          >
            <Box className="about-me-content">
              <Box className="about-me-image"></Box>
              <Box className="about-me-text">
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{ color: theme.palette.primary.contrastText }}
                >
                  About Me
                </Typography>
                <Typography
                  variant="body1"
                  className="about-me-text"
                  sx={{
                    color: theme.palette.primary.contrastText,
                    mt: "-30px",
                  }}
                >
                  Hello! I'm Davin Chiupka, a dynamic creator passionate about
                  music, photography, and coding. With a rich background in
                  computer programming, audio engineering, and web design.
                </Typography>
              </Box>
            </Box>
          </Paper>
        </motion.div>
        <Box className="my-work-section">
          <Typography variant="h2">My Work</Typography>
          <Grid container spacing={4}>
            {sections.map((section, index) => (
              <Grid item xs={16} sm={12} md={4} key={index}>
                <Tooltip title={`Go to ${section.title.toLowerCase()}`}>
                  <Link to={section.link} className="column-link">
                    <motion.div
                      className="card-container"
                      ref={(el) => (myWorkRefs.current[index] = el)}
                      initial="offscreen"
                      animate={cardControls}
                      variants={cardVariants}
                    >
                      <StyledCard>
                        <StyledCardMedia image={section.backgroundImage} />
                        <Content>
                          <Typography variant="h4" sx={classes.title}>
                            {section.title}
                          </Typography>
                          <Typography variant="body" sx={classes.subtitle}>
                            {section.description}
                          </Typography>
                        </Content>
                      </StyledCard>
                    </motion.div>
                  </Link>
                </Tooltip>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
