import React from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './NotFound.scss';

const NotFound = () => {
  const theme = useTheme();
  return (
    <Container className="page-container" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
      <Paper elevation={4} className="not-found-container" sx={{ mb: 2 }}>
       <FontAwesomeIcon class="error-icon" icon={faExclamationTriangle} style={{ fontSize: 20, color: theme.palette.warning.main }} />
        <Typography variant="h1" component="h1">
          404 Page Not Found
        </Typography>
        <Typography variant="h5" component="h2">
          Congratulations! You found a super rare page... that still doesn't exist.
        </Typography>
        <Typography variant="body1" component="p">
          Here are some tips to get you back on track:
        </Typography>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>
            <Typography variant="body1" component="p">
              Check the URL for typos – they're sneaky!
            </Typography>
          </li>
          <li>
            <Button component={Link} to="/" variant="contained" color="secondary" sx={{ mt: 2 }}>
              <Typography variant='p1' sx={{color: theme.palette.primary.contrastText}}>Return to the homepage – it's cozy and error-free.</Typography>
            </Button>
          </li>
          <li>
            <Typography variant="body1" component="p" sx={{ mt: 2 }}>
              If you think this page should exist,{' '}
              <Button
                component="a"
                href="mailto:admin@davinchiupka.info?subject=Page Not Found&body=I found a missing page!%0D%0AAdditional comments: "
                variant="contained"
                color="warning"
              >
                <Typography variant='p1' sx={{color: theme.palette.primary.contrastText}}>email me about the 404 page</Typography>
              </Button>
            </Typography>
          </li>
        </ul>
      </Paper>
    </Container>
  );
};

export default NotFound;
