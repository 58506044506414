import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch";
import { Link, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "./Header.scss"; // For custom styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { socialLinks } from "../../Utils/socialLinks";
import ThemedIcon from "../CustomIcons/ThemedIcon";

const links = [
  { path: "/", label: "Home" },
  { path: "/music", label: "Music" },
  { path: "/photography", label: "Photography" },
  { path: "/code", label: "Code" },
];

const Header = ({ isLightTheme, toggleTheme }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  const drawerList = () => (
    <Box
      sx={{ marginLeft: "auto", marginRight: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      className="mobile-nav"
    >
      <List>
        {links.map((link) => (
          <ListItem
            component={Link}
            to={link.path}
            key={link.path}
            onClick={() => handleLinkClick(link.path)}
          >
            <ListItemText
              primary={link.label}
              sx={{
                textAlign: "center",
                textTransform: "uppercase",
                color: activeLink === link.path ? theme.palette.secondary.dark : theme.palette.secondary.main,
                textShadow: activeLink === link.path ? "0px 10px 7px rgba(0,0,0,0.82);" : "none", 
              }}
              className="mobile-link"
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="fixed" className="sticky-header" color="default">
     <Toolbar>
  {isMobile ? (
    <>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <FontAwesomeIcon icon={faBars} color={theme.palette.primary.main} />
      </IconButton>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="mobile-drawer"
      >
        {drawerList()}
        <Box className="theme-toggle" sx={{ marginLeft: "auto", marginRight: "auto" }}>
          <Tooltip title="Toggle Light/Dark Mode">
            <span>
              <Switch checked={isLightTheme} onChange={toggleTheme} />
              <ThemedIcon icon={isLightTheme ? faSun : faMoon} />
            </span>
          </Tooltip>
        </Box>
        <Box>
          {socialLinks.map((link, index) => (
            <Tooltip key={index} title={link.title} className="mobile-social-container">
              <IconButton
                href={link.href}
                target="_blank"
                color="secondary"
              >
                <FontAwesomeIcon icon={link.icon} color="secondary" />
              </IconButton>
            </Tooltip>
          ))}
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography>&copy; 2023 Davin Chiupka.</Typography>
        </Box>
      </Drawer>
      <Tooltip title="Home">
        <span>
          <img
            src="/favicon.ico"
            alt="Icon"
            className="mobile-icon"
            onClick={() => (window.location.href = "/")}
          />
        </span>
      </Tooltip>
    </>
  ) : (
    <>
      <Tooltip title="Home">
        <span>
          <img
            src="/favicon.ico"
            alt="Icon"
            className="desktop-icon"
            onClick={() => (window.location.href = "/")}
          />
        </span>
      </Tooltip>
      <Box className="desktop-nav">
        {links.map((link) => (
          <Typography
            variant="h1"
            color="primary"
            key={link.path}
            component={Link}
            to={link.path}
            className="desktop-link"
            onClick={() => handleLinkClick(link.path)}
            sx={{
              color: activeLink === link.path ? theme.palette.primary.dark : theme.palette.secondary.light,
              textShadow: activeLink === link.path ? "2px 2px 4px rgba(0,0,0,0.3)" : "none",
              fontWeight: activeLink === link.path ? 800 : 600,
            }}
          >
            {link.label}
          </Typography>
        ))}
      </Box>
    </>
  )}
</Toolbar>

    </AppBar>
  );
};

export default Header;
