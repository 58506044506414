import React, { useState, useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import NotFound from "./Pages/NotFound/NotFound";
import Home from "./Pages/Home/Home";
import Music from "./Pages/Music/Music";
import Photography from "./Pages/Photography/Photography";
import Code from "./Pages/Code/Code";
import GamePage from "./Pages/Game/Game";
import ScrollToTop from "./Components/CustomButtons/ScrollToTopButton";
import { lightTheme, darkTheme } from './Utils/theme';
import './assets/Fonts/fonts.scss';
import "./App.scss";

function App() {
  const [isLightTheme, setIsLightTheme] = useState(true);

  useEffect(() => {
    const savedTheme = sessionStorage.getItem('isLightTheme');
    if (savedTheme) {
      setIsLightTheme(JSON.parse(savedTheme));
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = !isLightTheme;
    setIsLightTheme(newTheme);
    sessionStorage.setItem('isLightTheme', JSON.stringify(newTheme));
  };

  return (
    <BrowserRouter>
     <Box className="App-Container">
      <ThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
      <CssBaseline />
      <Header isLightTheme={isLightTheme} toggleTheme={toggleTheme} />
      <Box sx={{ marginTop: '60px' }}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/music" element={<Music />} />
        <Route path="/photography" element={<Photography />} />
        <Route path="/code" element={<Code />} />
        <Route path="/game" element={<GamePage/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ScrollToTop/>
      <Footer isLightTheme={isLightTheme} toggleTheme={toggleTheme} />
      </ThemeProvider>
      </Box>
    </BrowserRouter>
  );
}

export default App;
