import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ThemedIcon from '../CustomIcons/ThemedIcon';
import {faArrowDown} from "@fortawesome/free-solid-svg-icons";
import data from '../../assets/img/data.json';

const TagChips = ({ onSelectTags, selectedTags }) => {
  const [uniqueTags, setUniqueTags] = useState([]);

  useEffect(() => {
    const tags = data.flatMap(item => item.Tags);
    const uniqueTags = Array.from(new Set(tags));
    setUniqueTags(uniqueTags);
  }, []);

  const handleChipClick = (tag) => {
    if (selectedTags.includes(tag)) {
      onSelectTags(selectedTags.filter(t => t !== tag));
    } else {
      onSelectTags([...selectedTags, tag]);
    }
  };

  const handleClearSelection = () => {
    onSelectTags([]);
  };

  return (
    <Accordion color="primary">
      <AccordionSummary expandIcon={<ThemedIcon icon={faArrowDown} />} aria-controls="panel1a-content" id="panel1a-header">
      <Typography variant='h2' sx={{fontSize: '1.2em'}}>Filter by Tags: {selectedTags.length > 0 ? selectedTags.join(', ') : 'None'}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          {uniqueTags.map((tag, index) => (
            <Chip
              key={index}
              label={tag}
              onClick={() => handleChipClick(tag)}
              color={selectedTags.includes(tag) ? 'primary' : 'default'}
            />
          ))}
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Button variant="contained" color="error" onClick={handleClearSelection}>
            Clear Selection
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default TagChips;
