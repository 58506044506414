// src/theme.js
import { createTheme } from "@mui/material/styles";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#005F6A", 
      light: "#47B2C4", 
      dark: "#00444D", 
      contrastText: "#FFFFFF", 
    },
    secondary: {
      main: "#3A5B76", 
      light: "#6B85A2", 
      dark: "#273D53",
      contrastText: "#FFFFFF", 
    },
    background: {
      default: "#F5F5F5", 
      paper: "#FFFFFF",
    },
    text: {
      primary: "#212121", 
      secondary: "#424242", 
      disabled: "#9E9E9E", 
    },
    divider: "#BDBDBD",
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',

    h2: {
      fontWeight: 600,
      fontSize: '3em'
    },

    h4: {
      fontWeight: 500,
    },

    body1: {
      fontSize: '1.1em',
      fontWeight: 400,
    }
  },
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#FCFF4B",
    },
    secondary: {
      main: "#f4ac45",
    },
    background: {
      default: "#121212",
    },
    warning: {
      main: "#75b3fa",
    },
  },
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',

    h2: {
      fontWeight: 600,
      fontSize: '3em'
    },

    h4: {
      fontWeight: 500,
    },

    body1: {
      fontSize: '1.1em',
      fontWeight: 400,
    }
  },
});

export { lightTheme, darkTheme };
